<template>
  <div class="jumbotron" style="height: 100vh; margin-bottom: 0px">
    <div
      class="container"
      style="color: rgba(255, 255, 255, 1); margin: auto; width: 400px"
    >
      <img src="./assets/cit_logo_white.svg" style="height: 250px" />
      <h1 style="font-weight: 700; font-size: 40px; margin-bottom: 33px">
        Curtis Information Technologies
      </h1>
      <p style="font-size: 1.3rem;">Cloud Architecture, Telemetry, CI/CD</p>
      <p></p>
      <p style="font-size: 1.3rem;">Software Development, Systems Architecture, Integrations</p>
      <p></p>
      <p style="font-size: 1.3rem;">Consulting & Professional Services</p>
      <p style="margin-top: 66px; font-size: 1.3rem;">
        Please
        <a href="https://www.linkedin.com/in/josh-c-62024a81/" target="_blank"
          >contact me via LinkedIn</a
        >
      </p>
    </div>
  </div>
</template>

<script>
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url("@/assets/background_blur.jpeg");
  height: 100vh;
}
</style>
